import React from 'react';
import {Grid, Typography} from "../../ui";

export default function VersionFooter() {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{padding: 2, paddingLeft: 10, background: 'rgb(231, 231, 231)', position: 'fixed', bottom: 0}}
        >
            <Grid item xs='auto'>
                <Typography>© Neelyx Labs 2023</Typography>
            </Grid>
            <Grid item xs='auto'>
                <Typography sx={{fontSize: '11px', marginLeft:'11px'}}>{`${process.env?.REACT_APP_BRANCH_NAME ? process.env?.REACT_APP_BRANCH_NAME + ":" : ""}${process.env?.REACT_APP_SHORT_SHA || ""}`}</Typography>
            </Grid>
        </Grid>
    )
}