const constants = {
    API: {
        REQUEST_OTP: 'auth/request-otp',
        LOGIN: 'auth/login',
        REPORTS: 'reports/patient-reports',
        VIEW_REPORT: 'reports/patient-reports',
        PING: 'ping',
        PATIENT: '/patient',
        PATIENTS_REPORT: 'reports/client-reports',
    }, LOGIN_METHOD: {
        PHONE: 'phone', EMAIL: 'email',
    }, ROUTES: {
        LOGIN: '/',
        REPORT: '/my-reports',
        REQUISITION: '/create-requisitions-request',
        PATIENTS_REPORT: '/my-organization-reports',
    }, ERROR_MESSAGES: {
        INVALID_USER_LOGIN_ID: 'Please enter valid email address / phone number',
        INVALID_VERIFICATION_CODE: 'Please enter valid verification code'
    }, COPYRIGHT: '@Neelyx Labs 2022'
}

export default constants